import { template as template_cd601265e16a40f49180435337941fdc } from "@ember/template-compiler";
const FKControlMenuContainer = template_cd601265e16a40f49180435337941fdc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
