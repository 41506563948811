import { template as template_2d73e21eb325424b953180b8ddb707e8 } from "@ember/template-compiler";
const FKLabel = template_2d73e21eb325424b953180b8ddb707e8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
