import { template as template_53ce7e99fd554fdd83afef92d9c7bb95 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
import { i18n } from "discourse-i18n";
const PrivateMessageGlyph = template_53ce7e99fd554fdd83afef92d9c7bb95(`
  {{#if @shouldShow}}
    {{#if @href}}
      <a href={{@href}} title={{i18n @title}} aria-label={{i18n @ariaLabel}}>
        <span class="private-message-glyph-wrapper">
          {{icon "envelope" class="private-message-glyph"}}
        </span>
      </a>
    {{~else}}
      <span class="private-message-glyph-wrapper">
        {{icon "envelope" class="private-message-glyph"}}
      </span>
    {{~/if}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PrivateMessageGlyph;
