import { template as template_a61e9b59e1fb4b2a9224ad49865a52bd } from "@ember/template-compiler";
const FKText = template_a61e9b59e1fb4b2a9224ad49865a52bd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
