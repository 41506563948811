import { template as template_54e4c1b3ecd34683a726568a3573a07b } from "@ember/template-compiler";
const SidebarSectionMessage = template_54e4c1b3ecd34683a726568a3573a07b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
