import { template as template_36105892c7b04fc3aca5b89b3412b4b1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_36105892c7b04fc3aca5b89b3412b4b1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
